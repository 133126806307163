import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertyFeatures(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-feature', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchConditions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/condition')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyFeature(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-feature/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePropertyFeature(ctx, { propertyFeatureData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/property-feature/${id}`, propertyFeatureData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePropertyFeature(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/property-feature/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPropertyFeature(ctx, propertyFeatureData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/property-feature', propertyFeatureData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchValueTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/config/property_features.value_types')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyFeaturesConfig(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/config/property_features')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/property-type')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyFeatureGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/property-feature-group')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
