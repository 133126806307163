<template>
  <div>
    <!-- Form: PropertyFeature Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: display_name -->
          <validation-provider
            #default="validationContext"
            vid="display_name"
            name="display_name"
            rules="required"
          >
            <b-form-group
              label="إسم الخاصية"
              label-for="display_name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="display-name"
                v-model="propertyFeatureData.display_name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="معرّف الخاصية (english no spaces)"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="propertyFeatureData.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: property_type -->
          <validation-provider
            #default="validationContext"
            vid="property_type"
            name="property_type"
            rules="required"
          >
            <b-form-group
              label="أنواع العقار"
              label-for="property_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="propertyFeatureData.property_types"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="property_types"
                :reduce="(val) => val.value"
                :clearable="false"
                multiple
                input-id="property_type"
                @input="property_typeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: property_feature_group -->
          <validation-provider
            #default="validationContext"
            vid="property_feature_group"
            name="property_feature_group"
            rules="required"
          >
            <b-form-group
              label="إسم المجموعة"
              label-for="property_feature_group_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="propertyFeatureData.property_feature_group_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="property_feature_groups"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="property_feature_group_id"
                @input="property_feature_group_idChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: value_type -->
          <validation-provider
            #default="validationContext"
            vid="value_type"
            name="value_type"
            rules="required"
          >
            <b-form-group
              label="نوع الخاصية"
              label-for="value_type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="propertyFeatureData.value_type"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="value_types"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="value_type"
                @input="value_typeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <template
          v-if="
            ['integer', 'decimal', 'range'].includes(
              propertyFeatureData.value_type
            )
          "
        >
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Field: value_options -->
            <!-- ['enum', 'range', 'object', 'integer', 'decimal'] -->
            <validation-provider
              #default="validationContext"
              vid="value_options.min"
              name="value_options.min"
            >
              <b-form-group
                label="القيمة الدنيا"
                label-for="value_options.min"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="value_options.min"
                  v-model="propertyFeatureData.value_options['min']"
                  type="number"
                  step="1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              vid="value_options.max"
              name="value_options.max"
              rules="required"
            >
              <b-form-group
                label="القيمة القصوى"
                label-for="value_options.max"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="value_options.max"
                  v-model="propertyFeatureData.value_options['max']"
                  type="number"
                  step="1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              vid="value_options.step"
              name="value_options.step"
              rules="required"
            >
              <b-form-group
                label="الخطوة"
                label-for="value_options.step"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="value_options.step"
                  v-model="propertyFeatureData.value_options['step']"
                  type="number"
                  step="1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </template>
        <template v-if="['enum'].includes(propertyFeatureData.value_type)">
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Field: value_options -->
            <validation-provider
              #default="validationContext"
              vid="value_options.options"
              name="value_options.options"
            >
              <b-form-group
                label="الخيارات"
                label-for="value_options.options"
                description="ادخل الخيارات مفصولة بفاصلة أو نقطة مع فاصلة"
                :state="getValidationState(validationContext)"
              >
                <b-form-tags
                  v-model="propertyFeatureData.value_options['options']"
                  input-id="value_options.options"
                  separator=",;"
                  placeholder="ادخل الخيارات"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              vid="value_options.multiple"
              name="value_options.multiple"
              rules="required"
            >
              <b-form-group
                label="خيارات متعددة"
                label-for="value_options.multiple"
                :state="getValidationState(validationContext)"
              >
                <b-form-radio-group
                  v-model="propertyFeatureData.value_options['multiple']"
                  :options="yesNoOptions"
                  class="demo-inline-spacing"
                  default-value="0"
                  name="value_options.multiple"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </template>
        <b-col
          v-if="propertyFeatureData.condition"
          cols="12"
        >
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="12"
              lg="12"
            >
              <div class="d-flex">
                <feather-icon
                  icon="EyeIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  شروط العرض
                </h4>
              </div>
            </b-col>
            <b-col cols="12">
              <hr class="my-2">
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                vid="related_feature_id"
                name="related_feature_id"
                rules="required"
              >
                <b-form-group
                  label="تظهر لما تكون"
                  label-for="related_feature_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="propertyFeatureData.condition.related_feature_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="features"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="related_feature_id"
                    @input="related_featureChanged"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                vid="condition_id"
                name="condition_id"
                rules="required"
              >
                <b-form-group
                  label="عامل الشرط"
                  label-for="condition_id"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="propertyFeatureData.condition.condition_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="conditions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    input-id="condition_id"
                    @input="conditionChanged"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                vid="value"
                name="value"
                rules="required"
              >
                <b-form-group
                  label="القيمة"
                  label-for="value"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="propertyFeatureData.condition.value"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="relatedFeatureOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    :multiple="currentCondition && currentCondition.operator === 'IN'"
                    input-id="value"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updatePropertyFeature"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormTags,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import propertyFeatureStore from '../propertyFeatureStore';

export default {
  components: {
    BFormTags,
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    propertyFeatureData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
      yesNoOptions: [
        { text: 'نعم', value: true },
        { text: 'لا', value: false },
      ],
    };
  },
  methods: {
    updatePropertyFeature() {
      this.$store
        .dispatch('app-propertyFeature/updatePropertyFeature', {
          propertyFeatureData: this.propertyFeatureData,
          id: this.propertyFeatureData.id,
        })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push(`/control-panel/property-feature/${this.$route.params.feature}`).catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    conditionChanged(condition_id) {
      const condition = this.conditions.find((c) => c.id === condition_id);

      // if relatedFeature value_type is enum we need to get the options
      if (condition) {
        this.currentCondition = condition;
      }
    },
    related_featureChanged(related_feature_id) {
      const relatedFeature = this.features.find(
        (c) => c.id === related_feature_id,
      );
      this.relatedFeature = relatedFeature;
      // if relatedFeature value_type is enum we need to get the options
      if (relatedFeature && relatedFeature.value_options.options) {
        this.relatedFeatureOptions = relatedFeature.value_options.options.map((c) => ({
          label: c,
          value: c,
        }));
      }
      this.propertyFeatureData.condition.value = null;
    },
    value_typeChanged(value_type) {
      this.propertyFeatureData.value_type_id = value_type;
      this.propertyFeatureData.value_options = {};
    },
    property_typeChanged(property_type) {
      this.propertyFeatureData.property_type_id = property_type;
    },
    property_feature_group_idChanged(property_feature_group_id) {
      this.propertyFeatureData.property_feature_group_id = property_feature_group_id;
    },
  },

  setup(props, context) {
    const PROPERTYFEATURE_STORE_MODULE_NAME = 'propertyFeature';
    const { property_feature_group_id } = props.propertyFeatureData;
    // Register propertyFeature
    if (!store.hasModule(PROPERTYFEATURE_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTYFEATURE_STORE_MODULE_NAME,
        propertyFeatureStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYFEATURE_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYFEATURE_STORE_MODULE_NAME);
    });
    const value_types = ref([]);
    const features = ref([]);
    const relatedFeature = ref(null);
    const relatedFeatureOptions = ref([]);
    const currentCondition = ref(null);
    let res = store
      .dispatch('propertyFeature/fetchPropertyFeaturesConfig')
      .then((response) => {
        const { data } = response.data;
        value_types.value = data.value_types
          // We don't need object type for now
          .filter((c) => c !== 'object')
          .map((c) => ({
            label: context.root.$t(c),
            value: c,
          }));
      });
    const property_types = ref([]);
    res = store
      .dispatch('propertyFeature/fetchPropertyTypes')
      .then((response) => {
        const { data } = response;
        property_types.value = data.map((c) => ({
          label: c.name,
          value: c.id,
          ...c,
        }));
      });
    const property_feature_groups = ref([]);
    res = store
      .dispatch('propertyFeature/fetchPropertyFeatureGroups')
      .then((response) => {
        const { data } = response;
        property_feature_groups.value = data.map((c) => ({
          label: c.display_name,
          value: c.id,
          ...c,
        }));
      });

    const conditions = ref([]);
    res = store.dispatch('propertyFeature/fetchConditions').then((response) => {
      const { data } = response;
      conditions.value = data.map((c) => ({
        ...c,
        label: c.display_name,
        value: c.id,
      }));
      if (props.propertyFeatureData.condition) {
        currentCondition.value = conditions.value.find(
          (c) => c.id === props.propertyFeatureData.condition.condition.condition_id,
        );
      }
    });

    res = store
      .dispatch('propertyFeature/fetchPropertyFeatures')
      .then((response) => {
        const { data } = response;
        // we set the related feature options if there is a condition
        if (props.propertyFeatureData.condition) {
          const feature = data.find(
            (c) => c.id === props.propertyFeatureData.condition.related_feature_id,
          );
          relatedFeature.value = feature;
          // if feature value_type is enum we need to get the options
          if (feature && feature.value_options.options) {
            relatedFeatureOptions.value = feature.value_options.options.map(
              (c) => ({
                label: c,
                value: c,
              }),
            );
          }
        }
        features.value = data
          .filter(
            (c) => c.property_feature_group_id === property_feature_group_id,
          )
          .map((c) => ({
            label: c.display_name,
            value: c.id,
            ...c,
          }));
      });

    const oldPropertyFeatureData = JSON.parse(
      JSON.stringify(props.propertyFeatureData),
    );
    const resetPropertyFeatureData = () => {
      propertyFeatureData.value = JSON.parse(
        JSON.stringify(oldPropertyFeatureData),
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetPropertyFeatureData,
    );

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      features,
      currentCondition,
      conditions,
      value_types,
      property_types,
      property_feature_groups,
      relatedFeature,
      relatedFeatureOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
