<template>
  <component :is="propertyFeatureData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="propertyFeatureData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات خصائص العقار
      </h4>
      <div class="alert-body">
        لم يتم العثور على خصائص العقار مع معرف خصائص العقار هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'property-notes-list'}"
        >
          قائمة خصائص العقار
        </b-link>
        خصائص العقار الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="propertyFeatureData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t($route.params.feature) }}</span>
        </template>
        <propertyFeature-edit-tab-information
          :property-feature-data="propertyFeatureData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import propertyFeatureStore from '../propertyFeatureStore';
import PropertyFeatureEditTabInformation from './PropertyFeatureEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PropertyFeatureEditTabInformation,
  },
  watch: {
    propertyFeatureData(newData, oldData) {
      if (newData && !oldData) {
        const breadcrumb = [
          {
            text: this.$route.params.feature,
            to: {
              name: 'property-features-list',
              params: { id: this.$route.params.feature },
            },
          },
          {
            text: newData.display_name,
            active: true,
            to: {
              name: 'property-feature-edit',
              params: { feature: newData.feature, id: newData.id },
            },
          },
        ];

        this.$root.$emit('breadcrumb', breadcrumb);
      }
    },
  },
  setup() {
    const propertyFeatureData = ref(null);

    const PROPERTYFEATURE_APP_STORE_MODULE_NAME = 'app-propertyFeature';

    // Register module
    if (!store.hasModule(PROPERTYFEATURE_APP_STORE_MODULE_NAME)) store.registerModule(PROPERTYFEATURE_APP_STORE_MODULE_NAME, propertyFeatureStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYFEATURE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYFEATURE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-propertyFeature/fetchPropertyFeature', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: propertyFeature } = response.data;
        propertyFeatureData.value = propertyFeature;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          propertyFeatureData.value = undefined;
        }
      });

    return {
      propertyFeatureData,
    };
  },
};
</script>

<style>

</style>
